<template>
  <div class="reports">
    <Pauses />
  </div>
</template>

<script>
import Pauses from '@/components/pauses/Pauses.vue'
export default {
  components: {
    Pauses
  }
}
</script>
