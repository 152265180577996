<template>
  <div>
    <Audit />
  </div>
</template>
<script>
import Audit from '@/components/audit/Audit'
export default {
  components: {
    Audit
  }
}
</script>
