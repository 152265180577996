<template>
  <transition name="fade">
    <div style="width: 100%" v-if="showPauseForm">
      <div style="padding: 30px;">
        <div style="float: left; width: 20%; text-align: right;padding-right: 50px;">Room: </div>
        <div style="float: left; width: 80%">
          <v-select
          v-model="newPause.room"
          :items="availRooms"
          label="Rooms"
          item-text="name"
          item-value="id"
          item
          dense
          solo
          hide-details="auto"
          style="text-overflow: ellipsis !important;"
          v-on:input="e => filterRoomSelect(e)"
        ></v-select>
        </div>
      </div>
      <div style="padding: 30px;">
        <div style="clear: both; float: left; width: 20%; text-align: right;padding-right: 50px;">Devices:</div>
        <div style="float: left; width: 80%">
          <v-select
          v-model="newPause.device"
          :items="availDevices"
          label="Devices"
          item-text="name"
          item-value="id"
          :disabled="enableDeviceDropdown"
          item
          dense
          solo
          hide-details="auto"
          style="text-overflow: ellipsis !important;"
          v-on:input="e => filterDeviceSelect(e)"
        ></v-select>
        </div>
      </div>
      <div style="padding: 30px;">
        <div style="clear: both; float: left; width: 20%; text-align: right;padding-right: 50px;">End Date:</div>
        <div style="float: left; width: 80%; margin-top: -20px; ">
          <v-menu
          v-model="menu"
          :close-on-content-click="true"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="newPause.endAtInstant"
                label=""
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="newPause.endAtInstant"
              @input="menu = false"
              :min="new Date().toISOString().substr(0, 10)"
            ></v-date-picker>
          </v-menu>
        </div>
      </div>
      <div style="padding: 30px;">
        <div style="clear: both; float: left; width: 20%; text-align: right;padding-right: 50px;">Comment:</div>
        <div style="float: left; width: 80%; margin-top: -20px;">
          <v-text-field
          v-model="newPause.comment"
          autocomplete="off"
          placeholder="Type Here"
        >
        </v-text-field>
        </div>
      </div>
      <div style="clear: both;">
        <v-btn
          small
        class="ma-2"
        color="info"
        @click="createNewPause()"
        @keydown.enter="createNewPause()"
        :disabled="!formVerified"
        :loading="loading.includes('newPause')"
      >
        Save

      </v-btn>
      <v-btn
          small
        class="ma-2"
        color="warning"
        @click="togglePauseForm()"
        @keydown.enter="togglePauseForm()"
      >
        Cancel
      </v-btn>
    </div>
  </div>
  </transition>
</template>
<script>

import { cloneDeep } from 'lodash'

export default {
  name: 'NewPauseForm',
  props: {
    togglePauseForm: { type: Function },
    loading: { type: Array },
    rooms: { type: Array },
    devices: { type: Array },
    newPause: { type: Object },
    showPauseForm: { type: Boolean },
    formVerified: { type: Boolean },
    createNewPause: { type: Function },
    restrictMaxDate: { type: String }
  },
  data: () => ({
    menu: false
  }),
  computed: {
    availRooms () {
      let rooms = cloneDeep(this.rooms)
      return rooms
    },
    availDevices: {
      get () {
        let devices = cloneDeep(this.devices)
        if (!this.newPause?.room?.id) {
          return devices
        }

        let selectedRoom = this.rooms.find(e => e.id.includes(this.newPause.room.id))
        return devices.filter(d => d.name.includes(selectedRoom.name))
      }
    },
    enableDeviceDropdown: {
      get () {
        if (this.newPause?.room?.id) {
          return false
        }
        return true
      }
    }
  },
  methods: {
    filterRoomSelect (e) {
      const room = this.rooms.find(item => item.id === e)
      this.newPause.room = { id: room.id, name: room.name }
    },
    filterDeviceSelect (e) {
      const device = this.devices.find(item => item.id === e)
      this.newPause.device = { id: device.id, name: device.name }
      this.newPause.vendorDeviceId = device.vendorDeviceId
      this.newPause.deviceTypeId = device.deviceTypeId
    }
  }
}
</script>
