<template>
  <div>
    <h2 class="text-left mb-4 mt-4 mid-space-grid " style="margin-top: 0px !important;">
      <span>Event Log</span>
      <span></span>
      <v-btn
        :disabled="structuredData.length === 0"
        elevation="2"
        color="warning"
        medium
        @click="csvExport"
      >
        Export
      </v-btn>
    </h2>
    <div style="position: relative; display: flex; flex-direction: row; justify-content: flex-start; margin-bottom: 20px; align-items: flex-start;">
    <div v-for="item in filters" :key="item.typeId" style="margin-right: 20px; min-height: 40px;">
        <v-subheader>{{ item.type }}</v-subheader>
        <v-select
          :items="filterOpts[item.typeId]"
          :label="'Select ' + item.type"
          dense
          solo
          item-disabled="disabled"
          return-object
          :multiple="item.multiSelect"
          :item-text="item.selectText"
          hide-details="auto"
          style="min-width: 160px; max-width: 260px;"
          v-on:input="e => filterSelect(item.typeId, e)"
          v-model="displayProperty[item.typeId]"
        ></v-select>
      </div>
    </div>
    <v-sheet color="white" elevation="20" rounded shaped>
      <v-card>
        <v-card-title>
          <span style="margin-left: 10px; font-size: 0.92rem">
            {{ currSelectionSentence }}
          </span>
          <v-spacer></v-spacer>
          <v-text-field
            v-if="false"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :loading="isLoading"
          loading-text="Loading Report Data..."
          :headers="headers"
          :items="structuredData"
          :page.sync="page"
          :search="search"
        ></v-data-table>
      </v-card>
    </v-sheet>
  </div>
</template>
<script>
import { getReportData, getDataReportExport } from '@/api/devices'
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  data () {
    return {
      isLoading: false,
      search: '',
      page: 1,
      headers: [
        { text: 'Time & Date', value: 'name' },
        { text: 'Base', value: 'base' },
        { text: 'Location', value: 'locationName' },
        { text: 'Room', value: 'deviceContainer' },
        { text: 'Event Type', value: 'eventType' },
        { text: 'Resolution', value: 'resolution' },
        { text: 'Device SKU', value: 'deviceSerialNumber' }
      ],
      filters: [
        { typeId: 'time_date', selectVal: 'id', selectText: 'name', type: 'Time & Date', multiSelect: false },
        { typeId: 'room', selectVal: 'id', selectText: 'name', type: 'Room', multiSelect: true },
        { typeId: 'event_type', selectVal: 'id', selectText: 'name', type: 'Event Type', multiSelect: true },
        { typeId: 'resolution', selectVal: 'id', selectText: 'name', type: 'Resolution Type', multiSelect: true }
      ],
      filterOpts: {
        time_date: [
          { id: 'past_hour', name: 'Past Hour', selected: true },
          { id: 'past_twelve', name: 'Past 12 Hours' },
          { id: 'past_day', name: 'Past Day' },
          { id: 'past_three_days', name: 'Past 3 Days' },
          { id: 'past_week', name: 'Past Week' },
          { id: 'past_month', name: 'Past Month' },
          { id: 'past_three_months', name: 'Past 3 Months' },
          { id: 'past_six_months', name: 'Past 6 Months' }
        ],
        room: [
          // { name: 'All Rooms', id: 'all_rooms', selected: true }
        ],
        event_type: [
          // { id: 'all_events', name: 'All Events', selected: true },
          { id: 'fall', name: 'Fall' },
          { id: 'smart', name: 'Smart' },
          { id: 'presence', name: 'Presence / Absence' },
          { id: 'heartbeat', name: 'Online / Offline' }

        ],
        resolution: [
          { id: 'CONFIRMED_WITH_INJURY', name: 'Confirmed w/ Injury' },
          { id: 'CONFIRMED_WITHOUT_INJURY', name: 'Confirmed w/o Injury' },
          { id: 'CONFIRMED', name: 'Confirmed' },
          { id: 'UNRESOLVED', name: 'Unresolved' },
          { id: 'TRAINING', name: 'Training' },
          { id: 'UNCONFIRMED', name: 'Unconfirmed' }
        ]
      },
      displayProperty: {
        room: '',
        event_type: '',
        time_date: '',
        resolution: ''
      },
      structuredLocations: [],
      rawData: [],
      structuredData: [],
      unTouchedData: [],
      changeSentence: ''
    }
  },
  computed: {
    ...mapGetters({
      getContainerInfo: 'getContainerInfo',
      getCurrentCustomer: 'login/getCurrentCustomer',
      getCurrentFulfiller: 'login/getCurrentFulfiller',
      getCurrentCustomerNew: 'login/getCurrentCustomerNew',
      newToken: 'login/getNewToken',
      userNew: 'login/getUserNew'
    })
  },
  async mounted () {
    await this.reloadData()
  },
  methods: {
    ...mapActions({
      fetchContainers: 'containers/fetchContainers'
    }),
    async dbLoadData (chosenFilters) {
      this.isLoading = true
      const customer = this.getCurrentCustomerNew[0] || this.userNew.clientId
      const initialData = {
        beginInstant: this.filterByDateTime('past_hour'),
        phenomena: 'fall,presence'
      }
      const initialDataNew = {
        time: Date.parse(this.filterByDateTime('past_hour')),
        type: 'smart'
      }
      const data = {
        beginInstant: chosenFilters?.bg ? chosenFilters.bg : this.filterByDateTime('past_hour'),
        phenomena: chosenFilters?.ph.length ? chosenFilters.ph.filter(opt => opt !== 'smart').join(',') : 'fall,presence'
      }
      const dataNew = {
        time: Date.parse(chosenFilters?.bg) || Date.parse(this.filterByDateTime('past_hour')),
        type: 'smart'
      }
      let reportData = []
      let reportDataNew = []
      if (!chosenFilters) {
        reportData = await getReportData(initialData)
        reportDataNew = await getDataReportExport(initialDataNew, customer)
      } else {
        if (chosenFilters?.ph.length) {
          if (chosenFilters?.ph.length === 1 && chosenFilters?.ph.includes('smart')) {
            reportData = []
          } else {
            reportData = await getReportData(data)
          }
        }
        if (chosenFilters?.ph.length && chosenFilters?.ph.includes('smart')) {
          reportDataNew = await getDataReportExport(dataNew, customer)
        } else if (!chosenFilters?.ph.length) {
          reportData = await getReportData(data)
          reportDataNew = await getDataReportExport(dataNew, customer)
        }
      }
      const allData = reportData?.data ? reportData?.data.concat(reportDataNew) : reportDataNew
      const allDataSorted = allData.map(data => {
        if (data.meta?.instant) {
          let resolution = data?.data?.resolution?.data?.resolution
          if (resolution === 'REJECTED') {
            resolution = 'UNCONFIRMED'
          }
          if (resolution === 'SIMULATED') {
            resolution = 'TRAINING'
          }
          if (!resolution && data?.meta?.event?.did === 'report-fall') {
            resolution = 'UNRESOLVED'
          }
          return {
            ...data,
            startTime: Date.parse(data.meta?.instant),
            id: data?.container?.id || data?.container?.rootContainerId || data?.container?.parentId,
            room: data.data.container.fullyQualifiedName[data.data.container.fullyQualifiedName.length - 1],
            resolution: resolution
          }
        }
        if (data.type === 'SMART') {
          return {
            ...data,
            startTime: data.startTimestamp,
            room: data.roomType,
            resolution: this.transformStatus(data.status)
          }
        }
        return data
      })
      allDataSorted.sort((a, b) => b.startTime - a.startTime)
      this.rawData = cloneDeep(allDataSorted)
      this.unTouchedData = cloneDeep(allDataSorted)
      if (this.rawData) {
        this.isLoading = false
      }
    },
    async filterSelect (type, opt) {
      let answer
      this.page = 1
      switch (type) {
        case 'room':
          this.saveSelectedValue('room', opt, answer)
          this.saveSelectedValue('resolution', [])
          this.displayProperty['resolution'] = ''
          this.filterByRoom(opt)
          break
        case 'event_type':
          this.saveSelectedValue('event_type', opt, answer)
          this.saveSelectedValue('room', [])
          this.displayProperty['room'] = ''
          this.saveSelectedValue('resolution', [])
          this.displayProperty['resolution'] = ''
          await this.reRunRequest()
          break
        case 'time_date':
          answer = this.filterByDateTime(opt.id)
          this.saveSelectedValue('time_date', opt, answer)
          this.saveSelectedValue('room', [])
          this.displayProperty['room'] = ''
          this.saveSelectedValue('resolution', [])
          this.displayProperty['resolution'] = ''
          await this.reRunRequest()
          break
        case 'resolution':
          this.changeSentence = !this.changeSentence
          this.filterByResolution('resolution', opt, answer)
          this.saveSelectedValue('resolution', opt, answer)
          this.saveSelectedValue('room', [])
          this.displayProperty['room'] = ''
          break
        default:
          break
      }
    },
    transformStatus (status) {
      switch (status) {
        case 'confirmedWithoutInjury':
          return 'CONFIRMED_WITHOUT_INJURY'
        case 'confirmedWithInjury':
          return 'CONFIRMED_WITH_INJURY'
        case 'unconfirmed':
          return 'UNCONFIRMED'
        case 'training':
          return 'TRAINING'
        case 'confirmed':
          return 'CONFIRMED'
        case 'unresolved':
          return 'UNRESOLVED'
      }
    },
    transformStatusSubtypeEnum (data) {
      switch (data.statusSubtype) {
        case 'fallReported':
          return 'Fall - Reported'
        case 'fallDetected':
          if (data.daytime && !data.nighttime) {
            return 'Fall - Detected during daytime'
          }
          if (data.nighttime && !data.daytime) {
            return 'Fall - Detected during night-time'
          }
          return 'Fall - Detected'
        case 'inBathroomDuringNightTime':
          if (data.daytime && !data.nighttime) {
            return 'In bathroom during daytime'
          }
          if (data.nighttime && !data.daytime) {
            return 'In bathroom during night-time'
          }
          return 'In bathroom'
        case 'inBedDuringDaytime':
          if (data.daytime && !data.nighttime) {
            return 'In bed during daytime'
          }
          if (data.nighttime && !data.daytime) {
            return 'In bed during night-time'
          }
          return 'In bed'
        case 'outOfBed':
          if (data.daytime && !data.nighttime) {
            return 'Out of bed during daytime'
          }
          if (data.nighttime && !data.daytime) {
            return 'Out of bed during night-time'
          }
          return 'Out of bed'
        case 'outOfCoverageDuringInRoomOrNightTime':
          return 'Out of coverage during night-time'
        case 'awayFromApartment':
          if (data.daytime && !data.nighttime) {
            return 'Left the apartment during daytime'
          }
          if (data.nighttime && !data.daytime) {
            return 'Left the apartment during night-time'
          }
          return 'Left the apartment'
        case 'dwellInBathroom':
          return 'Dwell in bathroom'
      }
    },
    async filterByResolution (eventType, opts, answer) {
      let found = []
      if (opts.length) {
        found = this.unTouchedData.filter(data => {
          if (opts.some(op => op?.id === data.resolution)) {
            return data
          }
        })
      }
      this.rawData = []
      this.rawData = opts.length ? found : this.unTouchedData
    },
    async reRunRequest () {
      this.changeSentence = !this.changeSentence
      const chosenTime = this.filterOpts.time_date.filter(item => item.selected)
      const chosenPhenoms = this.filterOpts.event_type.filter(item => item.selected).map(i => i.id)
      await this.dbLoadData({ bg: chosenTime?.[0]?.value ?? null, ph: chosenPhenoms ?? '' })
    },
    filterByRoom (opts) {
      let found = []
      if (opts.length) {
        found = this.unTouchedData.filter(data => {
          if (!found.includes(data) && opts.some(op => op?.name === data?.room)) {
            return data
          }
        })
      }
      this.rawData = []
      this.rawData = found.length ? found : this.unTouchedData
      this.changeSentence = !this.changeSentence
    },
    convertDate (myDate) {
      return myDate.toLocaleString('en-US', { hour12: false, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, timeZoneName: 'short' })
    },
    formatSmartResolution (status, resolutionTimestamp) {
      return resolutionTimestamp
        ? `${status} at ${new Date(
                  parseInt(resolutionTimestamp)
                )}`
        : status
    },
    saveSelectedValue (eventType, opt, answer) {
      for (let i = 0; i < this.filterOpts[eventType].length; i++) {
        // First checks if opt is array and contains current id, if opt is not an array it checks the object's id
        if (
          (opt.length &&
            opt.some(el => el.id === this.filterOpts[eventType][i].id)) ||
          this.filterOpts[eventType][i].id === opt.id
        ) {
          this.filterOpts[eventType][i].value = answer
          this.filterOpts[eventType][i].selected = true
        } else {
          this.filterOpts[eventType][i].selected = false
        }
      }
    },
    filterByDateTime (curr) {
      let currDate = new Date()
      let manipDate
      if (curr === 'past_hour') {
        manipDate = currDate.setHours(currDate.getHours() - 1)
      } else if (curr === 'past_twelve') {
        manipDate = currDate.setHours(currDate.getHours() - 12)
      } else if (curr === 'past_day') {
        manipDate = currDate.setDate(currDate.getDate() - 1)
      } else if (curr === 'past_three_days') {
        manipDate = currDate.setDate(currDate.getDate() - 3)
      } else if (curr === 'past_week') {
        manipDate = currDate.setDate(currDate.getDate() - 7)
      } else if (curr === 'past_month') {
        manipDate = currDate.setMonth(currDate.getMonth() - 1)
      } else if (curr === 'past_three_months') {
        manipDate = currDate.setMonth(currDate.getMonth() - 3)
      } else if (curr === 'past_six_months') {
        manipDate = currDate.setMonth(currDate.getMonth() - 6)
      }
      const finalDay = new Date(manipDate).toISOString()
      return finalDay
    },
    csvExport (arrData) {
      let header = ['Date', 'Time', 'Base', 'Location', 'Room', 'Event Type', 'Resolution', 'Device SKU']
      let rows = this.structuredData.map(el => Object.values(el))
      rows.forEach(el => {
        if (el[5] !== undefined) {
          el[5] = el[5].replace(/,/g, '')
        } return el
      })

      let allContent = [header, ...rows]
      let csvContent = 'data:text/csv;charset=utf-8,' +
        allContent.map(e => e.join(',')).join('\n')
      var encodedUri = encodeURI(csvContent)
      var link = document.createElement('a')
      let currDate = new Date().toISOString()
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', `Report-${this.currSelectionSentence}-${currDate}.csv`)
      document.body.appendChild(link)
      link.click()
    },
    async reloadData () {
      this.currSelectionSentence = 'Report '
      this.isLoading = true
      this.structuredData = []
      this.displayProperty['room'] = ''
      this.displayProperty['event_type'] = ''
      this.displayProperty['time_date'] = ''
      this.displayProperty['resolution'] = ''
      this.saveSelectedValue('event_type', [])
      this.saveSelectedValue('room', [])
      this.saveSelectedValue('time_date', [])
      this.saveSelectedValue('resolution', [])
      this.filterOpts['time_date'] = this.filterOpts['time_date'].map(item => {
        if (item.id === 'past_hour') {
          return {
            ...item,
            selected: true
          }
        } else {
          return {
            ...item,
            selected: false
          }
        }
      })
      await this.fetchContainers() // Fetched for Room Filter List
      await this.reRunRequest()
    }
  },
  watch: {
    async getCurrentCustomer (newVal, oldVal) {
      if (oldVal) {
        if (newVal[0] !== oldVal[0]) {
          await this.reloadData()
          return
        }
      }
      if (!oldVal) {
        await this.reloadData()
      }
    },
    async getCurrentFulfiller (newVal, oldVal) {
      if (oldVal) {
        if (newVal[0] !== oldVal[0]) {
          await this.reloadData()
          return
        }
      }
      if (!oldVal) {
        await this.reloadData()
      }
    },
    async newToken (newVal) {
      if (newVal) {
        await this.reRunRequest()
      }
    },
    getContainerInfo (newVal) {
      let roomData = [] // = [{ name: 'All Rooms', id: 'all_rooms', selected: true }]
      for (const container in newVal) {
        roomData.push(newVal[container])
      }
      this.structuredLocations = roomData
    },
    changeSentence (newVal, oldVal) {
      let currSentence = 'Report '
      if (!this.filters || this.filters.length === 0) return
      for (const item of this.filters) {
        let foundMatch = false
        if (
          this.filterOpts[item.typeId] &&
          this.filterOpts[item.typeId].length > 0
        ) {
          for (const opt of this.filterOpts[item.typeId]) {
            if (opt.selected && !currSentence.includes(opt.name)) {
              currSentence += !foundMatch ? ' - ' + opt.name : ', ' + opt.name
              foundMatch = true
            }
          }
        }
        foundMatch = false
      }
      this.currSelectionSentence = currSentence
    },
    rawData (newVal) {
      this.structuredData = []
      let roomData = []
      const printingData = newVal.map(data => {
        if (data.type === 'SMART') {
          const id = data.id
          const name = data.roomType
          roomData.push({ id, name })
          return {
            name: this.convertDate(new Date(parseInt(data.startTimestamp))), // convert startTimestamp
            base: data.community,
            locationName: data.roomName,
            deviceContainer: data.roomType,
            eventType: this.transformStatusSubtypeEnum(data), // use new logic for this
            resolution: data.resolutionTimestamp
              ? `${data.status} at ${this.convertDate(new Date(
                  parseInt(data.resolutionTimestamp)
                ))}`
              : data.status,
            deviceSerialNumber: data.deviceId
          }
        }
        if (data?.meta) {
          const event = data?.data
          const meta = data?.meta
          const location = event?.container?.fullyQualifiedName
          const deviceSN = event?.device?.metadata?.serialNumber ?? 'No Device Identifier Found'
          let resolution = ''
          if (meta.event.did === 'go-offline') {
            resolution = event?.resolution && this.$t('devices.backOnline') +
              ' @ ' + this.convertDate(new Date(event?.resolution?.meta?.instant))
          } else if (data.resolution === 'UNRESOLVED') {
            resolution = 'unresolved'
          } else {
            resolution = event?.resolution &&
              (data.resolution || '') +
              (data.resolution ? ' @ ' : '') +
              this.convertDate(new Date(event?.resolution?.meta?.instant)) +
              ' by ' +
              (event?.resolution?.data?.user?.email ? event?.resolution?.data?.user?.email : event?.resolution?.data?.user?.username)
          }
          const { id, name } = event?.container
          roomData.push({ id, name })
          if (meta.event.did !== 'come-online') {
            return {
              name: this.convertDate(new Date(parseInt(data.startTime))),
              base: location?.[0],
              locationName: location?.[location?.length - 2],
              deviceContainer: location?.[location?.length - 1],
              eventType: (meta?.event?.did ? 'did-' : '') + meta?.event?.did ?? 'Unknown Event Type',
              resolution: resolution,
              deviceSerialNumber: deviceSN
            }
          }
        }
      })
      this.structuredData = printingData.filter(entry => entry)
      this.filterOpts = {
        ...this.filterOpts,
        room: roomData
      }
    }
  }
}
</script>
