<template>
  <div>
    <Alerts />
  </div>
</template>
<script>
import Alerts from '@/components/alerts/Alerts'
export default {
  components: {
    Alerts
  }
}
</script>
