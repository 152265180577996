<template>
    <td>
      <v-skeleton-loader
        ref="skeleton"
        :boilerplate="false"
        type="text"
        :tile="false"
        class="mx-auto"
        style="width: 100%; margin: auto;"
      ></v-skeleton-loader>
    </td>
</template>

<script>
export default {
  name: 'PauseTableSkeleton'
}
</script>
