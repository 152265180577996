<template>
  <div>
    <h2 class="text-left mb-4 mt-4 mid-space-grid ">
      <span>{{$t('features.pauses')}}</span>
      <span style="display: flex; justify-content: flex-end; margin-right: 10px;">
        <v-select
          :items="filterableRooms"
          label="Filter By Room"
          item-text="name"
          item-value="id"
          dense
          solo
          item-disabled="disabled"
          return-object
          :multiple="true"
          hide-details="auto"
          style="min-width: 180px; max-width: 360px;"
          v-on:input="e => filterSelect(e)"
        ></v-select>
      </span>
      <transition name="fade">
        <button v-if="!restrictedAccess" type="button" class="btn btn-warning justify-end" style="color: white; font-weight: bold;" @click="togglePauseForm()">
          New Pause
        </button>
      </transition>
    </h2>

    <NewPauseForm
      :showPauseForm="showPauseForm"
      :loading="loading"
      :rooms="rooms"
      :devices="devices"
      :newPause="newPause"
      :formVerified="formVerified"
      :togglePauseForm="togglePauseForm"
      :createNewPause="createNewPauseFunc"
    />
    <v-sheet
      width="98%"
      height="98%"
      :elevation="4"
      color="rgba(120,120,120,0.5)"
      :tile="false"
      style="margin: auto;"
    >
    <v-simple-table
      fixed-header height="100%"
      style=" margin: auto;"
    >
      <thead>
      <tr>
        <tr>
        <th scope="col" style="width: 16%; font-size: 17px;">Room</th>
        <th scope="col" style="width: 16%; font-size: 17px;">Device</th>
        <th scope="col" style="width: 15%; font-size: 17px;">Create At</th>
        <th scope="col" style="width: 16%; font-size: 17px;">End At</th>
        <th scope="col" style="width: 16%; font-size: 17px;">Comment</th>
        <th scope="col" style="width: 20%; font-size: 17px;"></th>
      </tr>
      </thead>
      <tbody style="text-align: left;">
        <tr v-for="skel in tableLoading" :key="skel">
          <PauseTableSkeleton v-for="item in [1,2,3,4,5,6]" :key="item + '-Skeleton'" />
        </tr>
        <tr><td> </td></tr>
        <tr v-for="(row, ind) in sortedRows" :key="row.uid">
        <td>
          <span>
          {{ row.room.name}}
          </span>
        </td>
        <td>
          {{ row.device.name}}
        </td>
        <td>
          {{row.createdAtInstant}}
        </td>
        <td v-if="ind === editingRow" style="padding: 1% 1% 0;" >
            <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormatted"
                label=""
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="computedDateFormatted"
              @input="menu = false"
              :min="new Date().toISOString().substr(0, 10)"
            ></v-date-picker>
          </v-menu>
        </td>
        <td v-else style="padding: 1%; padding-bottom: 1%;">
          {{row.endAtInstant}}
        </td>
        <td v-if="ind === editingRow" style="padding: 1% 1% 0;" >
            <v-text-field
          v-model="row.comment"
          autocomplete="off"
          placeholder="Type Here"
        >
        </v-text-field>
        </td>
        <td v-else style="padding: 1%; padding-bottom: 1%;">
          {{row.comment}}
        </td>
        <td>
          <PauseCrudBtns
            :ind="ind"
            :editRow="editRow"
            :trashRow="trashRow"
            :editingRow="editingRow"
            :loading="loading"
            :updatePause="updatePause"
            :restrictedAccess="restrictedAccess"
          />
        </td>
      </tr>
      </tbody>
    </v-simple-table>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { cloneDeep } from 'lodash'
import TreeModel from 'tree-model-improved'
import { combineLocation } from '@/utils/nameArrayShortener'
import NewPauseForm from '@/components/pauses/NewPauseForm'
import PauseTableSkeleton from '@/components/pauses/PauseTableSkeleton'
import PauseCrudBtns from '@/components/pauses/PauseCrudBtns'
import {
  getActiveDevicePauses,
  upsertDevicePause
} from '@/api/pauses'

const treeModel = new TreeModel({
  modelComparatorFn: (a, b) => a.name?.toLowerCase() > b.name?.toLowerCase()
})

export default {
  name: 'Pauses',
  components: {
    NewPauseForm,
    PauseTableSkeleton,
    PauseCrudBtns
  },
  data: () => ({
    rooms: [],
    devices: [],
    loading: [],
    items: [{}, {}, {}, {}, {}, {}],
    newPause: { room: {}, device: {}, vendorDeviceId: '', deviceTypeId: '', createdById: '', createdAtInstant: '', endAtInstant: '', errors: [] },
    rows: [],
    unfilteredRows: [],
    tableLoading: [1, 2, 3, 4, 5, 6],
    showPauseForm: false,
    pauseList: [],
    editingRow: null,
    menu: false,
    rowDate: null,
    date: new Date()
  }),
  computed: {
    ...mapGetters({
      getContainers: 'containers/getContainers',
      getCurrentCustomer: 'login/getCurrentCustomer',
      myInfo: 'login/myInfo',
      myIds: 'login/getMyIds'
    }),
    filterableRooms () {
      let rooms = cloneDeep(this.rooms)
      const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base'
      })

      rooms.sort(function (a, b) {
        return collator.compare(a.name, b.name)
      })

      return rooms
    },
    sortedRows () {
      const allRows = cloneDeep(this.rows)
      const sortedData = allRows.sort((a, b) => b.room.name > a.room.name ? -1 : 1)
      return sortedData
    },
    formVerified () {
      return !!(this.newPause.room && this.newPause.device && this.newPause.endAtInstant && !this.rows.find(item => item.device.id === this.newPause.device.id))
    },
    restrictedAccess () {
      const roles = ['SUPERADMIN', 'FULFILLER_ADMIN', 'CUSTOMER_ADMIN']
      return !roles.some(r => this.myInfo.roles.includes(r))
    },
    computedDateFormatted: {
      // getter
      get () {
        return this.rowDate.split('T')[0]
      },
      // setter
      set (newValue) {
        console.log('new value : ' + newValue)
        this.rowDate = new Date(newValue).toISOString()
      }
    }
  },
  async mounted  () {
    this.reloadData()
  },
  methods: {
    ...mapActions({
      fetchContainers: 'containers/fetchContainers',
      fetchMyInfo: 'login/fetchMyInfo'
    }),
    togglePauseForm () {
      if (this.showPauseForm) {
        this.initNewPause()
      }
      this.showPauseForm = !this.showPauseForm
    },
    filterSelect (roomTypeArray) {
      const allRows = cloneDeep(this.unfilteredRows)
      console.log('filter select', roomTypeArray, allRows)
      console.log('roomtype : ', roomTypeArray.length)
      console.log('allrows :', allRows.filter(row => roomTypeArray.some(el => el.id === row.room.id)))
      this.rows = roomTypeArray.length > 0 ? allRows.filter(row => roomTypeArray.some(el => el.id === row.room.id)) : this.unfilteredRows
      console.log(JSON.stringify(this.rows))
    },
    resetForms () {
      this.loading = []
      this.initNewPause()
      this.tableLoading = []
      this.showPauseForm = false
    },
    editRow (row) {
      this.editingRow = this.editingRow === row ? null : row
      console.log('edit row is ' + this.editingRow)
      if (!(this.editingRow === null)) this.rowDate = this.sortedRows[this.editingRow].endAtInstant
    },
    initNewPause () {
      this.newPause = { room: {}, device: {}, vendorDeviceId: '', deviceTypeId: '', createdAtInstant: '', createdById: '', endAtInstant: '', comment: '', errors: [] }
    },
    async createNewPauseFunc () {
      const data = {
        deviceId: this.newPause.device?.id,
        vendorDeviceId: this.newPause.vendorDeviceId,
        deviceTypeId: this.newPause.deviceTypeId,
        createdAtInstant: new Date().toISOString(),
        createdById: this.myInfo.id,
        endAtInstant: new Date(this.newPause.endAtInstant).toISOString(),
        idDeleted: false,
        comment: this.newPause.comment
      }
      this.loading.push('newPause')
      console.log(data)
      const devicePause = await upsertDevicePause({ devicePause: data })
      this.addPauseToTable(devicePause.data)
      this.resetForms()
    },
    async updatePause (row) {
      this.loading.push(row)
      console.log('update pause is : ' + row)
      const data = {
        id: this.sortedRows[row].uid,
        deviceId: this.sortedRows[row].device?.id,
        vendorDeviceId: this.sortedRows[row].vendorDeviceId,
        deviceTypeId: this.sortedRows[row].deviceTypeId,
        createdAtInstant: this.sortedRows[row].createdAtInstant,
        createdById: this.sortedRows[row].createdById,
        endAtInstant: this.sortedRows[row].endAtInstant,
        isDeleted: this.sortedRows[row].isDeleted,
        comment: this.sortedRows[row].comment
      }
      await upsertDevicePause({ devicePause: data })
      this.editRow(row)

      this.loading = []
      this.editingRow = null
    },
    async trashRow (row) {
      const data = {
        id: this.sortedRows[row].uid,
        deviceId: this.sortedRows[row].device?.id,
        vendorDeviceId: this.sortedRows[row].vendorDeviceId,
        deviceTypeId: this.sortedRows[row].deviceTypeId,
        createdAtInstant: this.sortedRows[row].createdAtInstant,
        createdById: this.sortedRows[row].createdById,
        endAtInstant: this.sortedRows[row].endAtInstant,
        isDeleted: true,
        comment: this.sortedRows[row].comment
      }

      await upsertDevicePause({ devicePause: data })

      this.editingRow = null
      const removedRow = this.sortedRows.splice(row, 1)
      console.log('deleting row : ' + JSON.stringify(removedRow))
      this.rows = this.rows.filter(item => item.uid !== removedRow[0].uid)
    },
    async addPauseToTable (pause) {
      // Get the device from the pause vendorDeviceId, this will allow us to retrieve room name and device name
      const device = this.devices.filter(item => item.vendorDeviceId === pause?.vendorDeviceId)[0]
      console.log('device is : ' + JSON.stringify(device))
      const room = this.rooms.filter(e => e.id === device.parentId)[0]
      const currItem = {
        room: { id: room?.id, name: room?.name },
        device: { id: device?.id, name: device?.name },
        vendorDeviceId: pause?.vendorDeviceId,
        deviceTypeId: pause?.deviceTypeId,
        createdAtInstant: pause?.createdAtInstant,
        createdById: pause?.createdById,
        endAtInstant: pause?.endAtInstant,
        isDeleted: pause?.isDeleted,
        comment: pause?.comment,
        uid: pause?.id
      }
      this.rows.push(currItem)
      this.unfilteredRows = this.rows
    },
    async reloadData () {
      await this.fetchContainers()
      const devicePauses = await getActiveDevicePauses()
      // Simulate Loading Period
      this.rows = []
      this.unfilteredRow = []
      for (const pause of devicePauses.data) {
        this.addPauseToTable(pause)
      }

      this.tableLoading = []
    }
  },
  watch: {
    getContainers (newVal, oldVal) {
      if (newVal) {
        this.items = []
        this.rooms = []
        this.devices = []
        treeModel.parse(cloneDeep(newVal)).walk(node => {
          const location = combineLocation(node?.model?.fullyQualifiedName, '/', 24) || node?.name
          // console.log(node)
          if (node.model?.deviceIds.length === 0 && node?.model?.fullyQualifiedName?.length > 1) {
            this.rooms.push({ name: location, id: node?.model?.id })
          } else if (node?.model?.fullyQualifiedName?.length > 1) {
            this.devices.push({ name: location, id: node?.model?.deviceIds[0], vendorDeviceId: node?.model?.devices[0].vendorDeviceId, deviceTypeId: node?.model?.devices[0].deviceTypeId, parentId: node?.parent?.model?.id })
          }
        })
      }
    },
    async getCurrentCustomer (newVal) {
      if (newVal) {
        this.unfilteredRows = []
        this.rows = []
        await this.reloadData()
      }
    },
    rowDate (val) {
      this.sortedRows[this.editingRow].endAtInstant = new Date(val).toISOString()
    }
  }
}
</script>

<style>
.intuitive-flex {
  display: flex; justify-content: flex-start; align-items: center; flex-direction: row;
}
.table-cell-double-input {
  display: flex;  width: 45%; margin:  2.5% 2.5% auto 2.5%; float: left;
}
@media all and (max-width: 800px){
  .intuitive-flex {
    flex-direction: column;
  }
}
.mid-space-grid {
  width: 95%; margin: auto;
}
.v-data-table {
  padding: 20px;
}
v-btn { margin: auto 8px; }
</style>
