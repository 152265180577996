<template>
  <div>
    <h2 class="text-left mb-4 mt-4 mid-space-grid " style="margin-top: 0px !important;">
      <span>Alerts</span>
      <span></span>
        <v-btn
          :disabled="structuredData.length === 0"
          elevation="2"
          color="warning"
          medium
          @click="csvExport"
        >
          Export
        </v-btn>
    </h2>
    <div style="position: relative; display: flex; flex-direction: row; justify-content: flex-start; margin-bottom: 20px; align-items: flex-start;">
      <div v-for="item in filters" :key="item.typeId" style="margin-right: 20px; min-height: 40px;">
        <v-subheader>{{ item.type }}</v-subheader>
        <v-select
          :items="filterOpts[item.typeId]"
          :label="'Select ' + item.type"
          dense
          solo
          item-disabled="disabled"
          return-object
          :multiple="item.multiSelect"
          :item-text="item.selectText"
          hide-details="auto"
          style="min-width: 160px; max-width: 260px;"
          v-on:input="e => filterSelect(item.typeId, e)"
          v-model="displayProperty[item.typeId]"
        ></v-select>
      </div>
    </div>
    <v-sheet color="white"
             elevation="20"
             rounded
             shaped>
      <v-card >
        <v-card-title>

          <span style="margin-left: 10px; font-size: 0.92rem">
            {{ currSelectionSentence }}
          </span>
          <v-spacer></v-spacer>
          <v-text-field
            v-if="false"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :loading="isLoading"
          loading-text="Loading Report Data..."
          :headers="headers"
          :items="structuredData"
          :server-items-length="totalAlertNumber"
          :search="search"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          @pagination="paginationChanged"
        ></v-data-table>
      </v-card>
    </v-sheet>
  </div>
</template>
<script>
import { getAlertData } from '@/api/devices'
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  data () {
    return {
      isLoading: false,
      search: '',
      page: 1,
      itemsPerPage: 10,
      headers: [
        { text: 'Time & Date', value: 'name' },
        { text: 'Base', value: 'base' },
        { text: 'Location', value: 'locationName' },
        { text: 'Room', value: 'deviceContainer' },
        { text: 'Alert Type', value: 'alertType' },
        { text: 'Resolution', value: 'resolution' },
        { text: 'Device SKU', value: 'deviceSerialNumber' }
      ],
      filters: [
        { typeId: 'time_date', selectVal: 'id', selectText: 'name', type: 'Time & Date', multiSelect: false },
        { typeId: 'room', selectVal: 'id', selectText: 'name', type: 'Room', multiSelect: true },
        { typeId: 'location', selectVal: 'id', selectText: 'name', type: 'Location', multiSelect: true },
        { typeId: 'alert_type', selectVal: 'id', selectText: 'name', type: 'Alert Type', multiSelect: false },
        { typeId: 'resolution', selectVal: 'id', selectText: 'name', type: 'Resolution Type', multiSelect: true },
        { typeId: 'time_of_day', selectedVal: 'id', selectText: 'name', type: 'Time of Day', multiSelect: false }
      ],
      filterOpts: {
        time_date: [
          { id: 'past_hour', name: 'Past Hour', selected: true },
          { id: 'past_twelve', name: 'Past 12 Hours' },
          { id: 'past_day', name: 'Past Day' },
          { id: 'past_three_days', name: 'Past 3 Days' },
          { id: 'past_week', name: 'Past Week' },
          { id: 'past_month', name: 'Past Month' },
          { id: 'past_three_months', name: 'Past 3 Months' },
          { id: 'past_six_months', name: 'Past 6 Months' }
        ],
        room: [
          // { name: 'All Rooms', id: 'all_rooms', selected: true }
        ],
        location: [

        ],
        alert_type: [
          // { id: 'all_events', name: 'All Events', selected: true },
          { id: 'detect_fall', name: 'detect-fall' },
          { id: 'report_fall', name: 'report-fall' },
          { id: 'exit_fall', name: 'exit-fall' },
          { id: 'finish_fall', name: 'finish-fall' },
          { id: 'cancel_fall', name: 'cancel-fall' },
          { id: 'detect_presence', name: 'detect-presence' },
          { id: 'detect_absence', name: 'detect-absence' },
          { id: 'go_offline', name: 'go-offline' }
        ],
        resolution: [
          { id: 'CONFIRMED_WITH_INJURY', name: 'Confirmed w/ Injury' },
          { id: 'CONFIRMED_WITHOUT_INJURY', name: 'Confirmed w/o Injury' },
          { id: 'SIMULATED', name: 'Simulated' },
          { id: 'UNCONFIRMED', name: 'Unconfirmed' }
        ],
        time_of_day: [
          { id: 'Day', name: 'Day Time' },
          { id: 'Night', name: 'Night Time' }
        ]
      },
      displayProperty: {
        room: '',
        location: '',
        alert_type: '',
        time_date: '',
        resolution: '',
        time_of_day: ''
      },
      structuredLocations: [],
      rawData: [],
      structuredData: [],
      totalAlertNumber: 0,

      unTouchedData: [],
      changeSentence: false,
      currSelectionSentence: ''
    }
  },
  computed: {
    ...mapGetters({
      getContainerInfo: 'getContainerInfo',
      getCurrentCustomer: 'login/getCurrentCustomer',
      getCurrentFulfiller: 'login/getCurrentFulfiller'
    })
  },
  async mounted  () {
    await this.reloadData()
  },
  methods: {
    ...mapActions({
      fetchContainers: 'containers/fetchContainers'
    }),
    async dbLoadData (chosenFilters = {}) {
      this.isLoading = true
      const data = {
        limit: this.itemsPerPage,
        offset: (this.page - 1) * this.itemsPerPage,
        beginInstant: chosenFilters?.bg ? chosenFilters.bg : this.filterByDateTime('past_hour'),
        phenomenonAction: chosenFilters?.ac ? chosenFilters.ac : 'report_fall'
      }
      const alertData = await getAlertData(data)
      this.rawData = cloneDeep(alertData)
      this.unTouchedData = cloneDeep(alertData)
      this.totalAlertNumber = alertData.data.totalAlertNumber
      if (alertData) {
        this.isLoading = false
      }
    },
    async filterSelect (type, opt) {
      let answer
      this.page = 1
      switch (type) {
        case 'room':
          this.saveSelectedValue('room', opt, answer)
          this.filterByRoom(opt)
          break
        case 'location':
          this.saveSelectedValue('location', opt, answer)
          this.filterByLocation(opt)
          break
        case 'alert_type':
          this.saveSelectedValue('alert_type', opt, answer)
          this.saveSelectedValue('room', [])
          this.displayProperty['room'] = ''
          this.saveSelectedValue('resolution', [])
          this.displayProperty['resolution'] = ''
          this.saveSelectedValue('time_of_day', [])
          this.displayProperty['time_of_day'] = ''
          await this.reRunRequest()
          break
        case 'time_date':
          answer = this.filterByDateTime(opt.id)
          this.saveSelectedValue('time_date', opt, answer)
          this.saveSelectedValue('room', [])
          this.displayProperty['room'] = ''
          this.saveSelectedValue('resolution', [])
          this.displayProperty['resolution'] = ''
          this.saveSelectedValue('time_of_day', [])
          this.displayProperty['time_of_day'] = ''
          await this.reRunRequest()
          break
        case 'resolution':
          answer = await this.filterByResolution('resolution', opt, answer)
          this.saveSelectedValue('room', [])
          this.displayProperty['room'] = ''
          this.saveSelectedValue('time_of_day', [])
          this.displayProperty['time_of_day'] = ''
          this.saveSelectedValue('resolution', opt, answer)

          break
        case 'time_of_day':
          answer = this.saveSelectedValue('time_of_day', opt, answer)
          this.saveSelectedValue('room', [])
          this.displayProperty['room'] = ''
          this.saveSelectedValue('resolution', [])
          this.displayProperty['resolution'] = ''
          this.filterByTimeOfDay(opt)
          break
        default:
          break
      }
      this.changeSentence = !this.changeSentence
    },
    async filterByResolution (alertType, opts, answer) {
      let found = []
      if (opts.length) {
        for (const el of this.unTouchedData?.data.events) {
          let resolution = el?.data?.resolution?.data?.resolution
          if (resolution === 'REJECTED') {
            resolution = 'UNCONFIRMED'
          }
          if (opts.some(op => op?.id === resolution)) {
            found.push(el)
          }
        }
      }
      this.rawData = {}
      this.rawData = { ...this.unTouchedData, data: !opts.length ? this.unTouchedData.data : { events: found } }
    },
    async reRunRequest () {
      const chosenTime = this.filterOpts.time_date.filter(item => item.selected)
      const chosenActions = this.filterOpts.alert_type.filter(item => item.selected).map(i => i.id).join(',')
      await this.dbLoadData({ bg: chosenTime?.[0]?.value ?? null, ac: chosenActions ?? '' })
    },
    filterByRoom (opts) {
      let found = []
      if (opts.length) {
        for (const el of this.unTouchedData?.data.events) {
          if (!found.includes(el) && opts.some(op => op?.id === el?.data?.container?.id || op?.id === el?.data?.container?.rootContainerId || op?.id === el?.data?.container?.parentId)) {
            found.push(el)
          }
        }
      }
      this.rawData = {}
      this.rawData = { ...this.unTouchedData, data: !opts.length ? this.unTouchedData.data : { events: found } }
    },
    filterByLocation (opts) {
      let found = []
      if (opts.length) {
        for (const el of this.unTouchedData?.data.events) {
          if (!found.includes(el) && opts.some(op => op?.id === el?.data?.container?.name)) {
            found.push(el)
          }
        }
      }
      this.rawData = {}
      this.rawData = { ...this.unTouchedData, data: !opts.length ? this.unTouchedData.data : { events: found } }
    },
    filterByTimeOfDay (opts) {
      let found = []
      for (const el of this.unTouchedData?.data.events) {
        let h = new Date(this.convertDate(new Date(el?.meta?.instant))).getHours()
        if (!found.includes(el) && (opts?.id === 'Day' ? (h >= 6 && h <= 18) : (h < 6 || h > 18))) {
          found.push(el)
        }
      }
      this.rawData = {}
      this.rawData = { ...this.unTouchedData, data: !opts.id ? this.unTouchedData.data : { events: found } }
    },
    convertDate (myDate) {
      return myDate.toLocaleString('en-US', { hour12: false, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, timeZoneName: 'short' })
    },
    saveSelectedValue (alertType, opt, answer) {
      for (let i = 0; i < this.filterOpts[alertType].length; i++) {
        // First checks if opt is array and contains current id, if opt is not an array it checks the object's id
        if ((opt.length && opt.some(el => el.id === this.filterOpts[alertType][i].id)) || this.filterOpts[alertType][i].id === opt.id) {
          this.filterOpts[alertType][i].value = answer
          this.filterOpts[alertType][i].selected = true
        } else {
          this.filterOpts[alertType][i].selected = false
        }
      }
    },
    filterByDateTime (curr) {
      let currDate = new Date()
      let manipDate
      if (curr === 'past_hour') {
        manipDate = currDate.setHours(currDate.getHours() - 1)
      } else if (curr === 'past_twelve') {
        manipDate = currDate.setHours(currDate.getHours() - 12)
      } else if (curr === 'past_day') {
        manipDate = currDate.setDate(currDate.getDate() - 1)
      } else if (curr === 'past_three_days') {
        manipDate = currDate.setDate(currDate.getDate() - 3)
      } else if (curr === 'past_week') {
        manipDate = currDate.setDate(currDate.getDate() - 7)
      } else if (curr === 'past_month') {
        manipDate = currDate.setMonth(currDate.getMonth() - 1)
      } else if (curr === 'past_three_months') {
        manipDate = currDate.setMonth(currDate.getMonth() - 3)
      } else if (curr === 'past_six_months') {
        manipDate = currDate.setMonth(currDate.getMonth() - 6)
      }
      const finalDay = new Date(manipDate).toISOString()
      return finalDay
    },
    csvExport (arrData) {
      let header = this.headers.map(el => el.text)
      let rows = this.structuredData.map(el => Object.values(el))
      let allContent = [header, ...rows]
      let csvContent = 'data:text/csv;charset=utf-8,' +
        allContent.map(e => e.join(',')).join('\n')
      var encodedUri = encodeURI(csvContent)
      var link = document.createElement('a')
      let currDate = new Date().toISOString()
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', `Report-${this.currSelectionSentence}-${currDate}.csv`)
      document.body.appendChild(link) // Required for FF

      link.click() // This will download the data file named "my_data.csv".
    },
    defaultState () {
      this.currSelectionSentence = 'Report'
      this.isLoading = true
      this.structuredData = []
      this.displayProperty['room'] = ''
      this.displayProperty['location'] = ''
      this.displayProperty['alert_type'] = ''
      this.displayProperty['time_date'] = ''
      this.displayProperty['resolution'] = ''
      this.displayProperty['time_of_day'] = ''
      this.saveSelectedValue('alert_type', [])
      this.saveSelectedValue('room', [])
      this.saveSelectedValue('location', [])
      this.saveSelectedValue('time_date', [])
      this.saveSelectedValue('resolution', [])
      this.saveSelectedValue('time_of_day', [])
      this.filterOpts['time_date'].forEach(item => {
        if (item.id === 'past_hour') {
          item.selected = true
        } else {
          item.selected = false
        }
      })
    },
    async reloadData () {
      this.defaultState()
      await this.fetchContainers() // Fetched for Room Filter List
      await this.dbLoadData()
      this.changeSentence = !this.changeSentence
    },
    paginationChanged (event) {
      if (event.itemsLength) {
        this.reRunRequest()
      }
    }
  },
  watch: {
    async getCurrentCustomer (newVal) {
      if (newVal) {
        await this.reloadData()
      }
    },
    async getCurrentFulfiller (newVal) {
      if (newVal) {
        await this.reloadData()
      }
    },
    getContainerInfo (newVal) {
      let roomData = [] // = [{ name: 'All Rooms', id: 'all_rooms', selected: true }]
      for (const container in newVal) {
        roomData.push(newVal[container])
      }
      this.structuredLocations = roomData
    },
    changeSentence (newVal) {
      let currSentence = 'Report '
      if (!this.filters || this.filters.length === 0) return
      for (const item of this.filters) {
        let foundMatch = false
        if (this.filterOpts[item.typeId] && this.filterOpts[item.typeId].length > 0) {
          for (const opt of this.filterOpts[item.typeId]) {
            if (opt.selected && !currSentence.includes(opt.name)) {
              currSentence += !foundMatch ? ' - ' + opt.name : ', ' + opt.name
              foundMatch = true
            }
          }
        }
        foundMatch = false
      }
      this.currSelectionSentence = currSentence
    },
    rawData (newVal) {
      this.structuredData = []
      let roomData = []
      let locationData = []
      for (let i = 0; i < newVal.data.events.length; i++) {
        const event = newVal.data.events[i]?.data
        const meta = newVal.data.events[i]?.meta
        const location = event?.container?.fullyQualifiedName
        const deviceSN = event?.device?.metadata?.serialNumber ?? 'No Device Identifier Found'
        let resolution = event?.resolution?.data?.resolution
        if (resolution === 'REJECTED') {
          resolution = 'UNCONFIRMED'
        }
        if (meta.event.did === 'go-offline') {
          resolution = event?.resolution && this.$t('devices.backOnline') +
            ' @ ' + this.convertDate(new Date(event?.resolution?.meta?.instant))
        } else {
          resolution = event?.resolution &&
            (resolution || '') +
            (resolution ? ' @ ' : '') +
            this.convertDate(new Date(event?.resolution?.meta?.instant)) +
            ' by ' +
            (event?.resolution?.data?.user?.email ? event?.resolution?.data?.user?.email : event?.resolution?.data?.user?.username)
        }
        if (meta.event.did !== 'come-online') {
          this.structuredData.push({
            name: this.convertDate(new Date(newVal.data.events[i]?.meta?.instant)),
            base: location?.[0],
            locationName: location?.[location?.length - 1],
            deviceContainer: location?.[location?.length - 2],
            alertType: (meta?.event?.did ? 'did-' : '') + meta?.event?.did ?? 'Unknown Alert Type',
            resolution: resolution,
            deviceSerialNumber: deviceSN
          })
          const id = event?.container.id
          const name = location?.[location?.length - 2]
          const sensor = location?.[location?.length - 1]
          roomData.push({ id, name, sensor })
          locationData.push({ id: sensor, name: sensor })
        }
      }
      console.log('roomdata : ' + JSON.stringify(roomData))
      this.filterOpts = {
        ...this.filterOpts,
        room: roomData,
        location: locationData
      }
    }
  }
}

</script>
